<template>
  <div style="height: 100%, width: 100%">
    <b-modal :title="title"
        v-model="enumShow"
        @ok="ok"
        @hidden="closeModal"
        content-class="shadow"
        no-close-on-backdrop
        >

      <template v-if="isMissingKeyData">
        <div class="modal-message-overlay">
        <span class="grid-overlay">{{ $t('enum.error_missing_key_data') }}</span>
        </div>
      </template>
      <template v-else>
        <b-row>
          <b-col cols="12">
            <b-form-group class="mb-0" :label="$t('value')">
              <b-input-group>
                <b-form-input ref="enumCode" v-model="enumCode" maxlength="25" @keyup="enumCodeKeyUp" :class="{ 'is-invalid': isCodeDuplicated }"></b-form-input>
              </b-input-group>
              <b-form-invalid-feedback class="alert-danger form-field-alert" style="margin-top: 1px" :class="{ 'd-block': isCodeDuplicated }">
                <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ $t('task.error.task_name_already_exists') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
      
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button :disabled="enumCode === null || enumCode.trim().length == 0 || isCodeDuplicated || enumCode.trim() == selectedCode" size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "EnumModal",
  components : {
    
  },
  props: {
    show: {
      type: Boolean, required: true
    },
    selectedNum: {
      type: Number, default: -1 
      //But this modal doesn't handle enum in disabled state. 
      //So -1 here is treated as unknown or null
    },
    selectedCode: {
      type: String, default: null
    },
    enumName: {
      type: String, default: null
    },
    enumObj: {
      type: Object, default: () => {
        return {
          list: [],
          disabledList: []
        }
      }
    }
  },
  data() {
    return {
      enumShow: false,
      enumCode: null,
      isCodeDuplicated: false
    }
  },
  beforeMount() {
    this.initModal();
  },
  mounted() {
    this.enumShow = this.show;
    if (this.enumShow) {
      setTimeout(() => {
        this.$refs.enumCode.focus();
      }, 300)
    }
  },
  computed: {
    isMissingKeyData() {
      return this.enumName == null
    }
    , title() {
      return this.enumName != null? this.$t(`enum.title_${this.selectedNum == -1? 'new':'edit'}`, [this.$t(`enum.${this.enumName}`)]) : ''
    }
  },
  watch: {
    show(nValue) {
      this.enumShow = nValue;
      if (this.enumShow) {
        this.initModal();
        setTimeout(() => {
          this.$refs.enumCode.focus();
        }, 300)
      }
    }
  },
  methods: {
    initModal() {
      this.enumCode = this.selectedCode;
    },
    ok() {
      this.$emit('ok', { code: this.enumCode.trim(), num: this.selectedNum == -1? null : this.selectedNum });
      this.closeModal();
    },
    enumCodeKeyUp(event) {
      const newCode = this.enumCode;
      if (newCode == this.selectedCode) {
        this.isCodeDuplicated = false;
      } else if (this.enumObj.list.find(i => i.code == newCode) != null) {
        this.isCodeDuplicated = true;
      } else {
        this.isCodeDuplicated = false;
      }
      if (this.isCodeDuplicated != true && event.which === 13 && this.enumCode != null && this.enumCode.trim().length > 0) {
        this.ok();
      }
    },
    closeModal() {
      this.$emit('update:show', false);
    }
  }
}
</script>

<style lang="scss" scoped>
.color-label {
  margin-bottom: 7px;
}
</style>