<template>
  <div :id="`DATE_TIME`" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="sm" :title="labelTitle" footer-class="footerClass" 
      no-close-on-backdrop  content-class="datetime-modal shadow"
      @hidden="hidden">

      <b-alert variant="danger" dismissible v-model="alertError">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ $t('error.attention_required') }} 
      </b-alert>

      <b-form-group label-for="generic-date" class="mb-0">
        <b-row>
          <b-col cols="12" v-if="editMode != 2">
            <b-form-datepicker id="generic-date" v-model="date" class="mb-2"
              today-button
              :reset-button="isOptional"
              close-button
              hide-header
              :label-today-button="$t('date.today')"
              :label-reset-button="$t('date.reset')"
              :label-close-button="$t('date.close')"
              today-button-variant="primary"
              reset-button-variant="danger" 
              close-button-variant="secondary"
            >
              <template v-slot:button-content="{ }">
                <font-awesome-icon :icon="['far', 'calendar-days']" />
              </template>
            </b-form-datepicker>
          </b-col>
          <b-col cols="12" v-if="editMode != 1">
            <Timepicker v-model="time">
            </Timepicker>
          </b-col>
        </b-row>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDateTimeError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('generic_datetime') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" v-if="isOptional" class="button-float-left" variant="secondary" @click="clear">{{ $t('button.clear') }}</b-button>
        <b-button size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
moment.locale(locale);
import { fieldValidateUtil } from '@/script/helper-field-validate';

export default {
  name: 'DateTimeModal',
  components: {
    Timepicker: () => import('@/components/Calendar/Timepicker.vue')
  },
  props: {
    constraintType: {
      type: String,
      default: null
    },
    dateTime: {
      type: Number,
      default: null
    },
    show: {
      type: Boolean,
      required: true
    },
    editMode: {
      type: Number,
      default: 0 //Possible value: 0: date & time | 1: date | 2: time
    },
    permissionName: {
      type: String,
      default: 'TASK'
    },
    isOptional: {
      type: Boolean,
      default: false
    },
    labelDate: {
      type: String,
      default: null
    },
    labelTime: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      modalShow: false,
      alertError: false,
      date: null,
      time: null
    }
  },
  created() {
    this.fieldValidateUtil = fieldValidateUtil;
    this.updateShow(this.show);
    this.updateDateTime(this.dateTime);
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
  },
  watch: {
    show(newValue) {
      this.updateShow(newValue);
      if(!newValue) {
        this.resetProperties();
      }
    },
    dateTime(newValue) {
      this.updateDateTime(newValue);
    }
  },
  computed: {
    showDateTimeError() {
      return fieldValidateUtil.hasError(this.errors, 'generic_datetime');
    },
    labelDateStr() {
      return this.labelDate != null? this.labelDate : this.$t('datetime.field.date');
    },
    labelTimeStr() {
      return this.labelTime != null? this.labelTime : this.$t('datetime.field.time');
    },
    labelTitle() {
      return this.editMode == 2? this.labelTimeStr : this.labelDateStr;
    }
  },
  methods: {
    updateShow(newValue) {
      this.modalShow = newValue;
    },
    updateDateTime(newValue) {
      if(newValue != null) {
        const dateInstance  = moment(newValue).utc();
        if (this.editMode == 1) {
          this.date = dateInstance.format('YYYY-MM-DD');
          this.time = null;
        } else if (this.editMode == 2) {
          this.date = null;
          this.time = dateInstance.format('HH:mm:00');
        } else {
          this.date = dateInstance.format('YYYY-MM-DD');
          this.time = dateInstance.format('HH:mm:00');
        }
      } else {
        this.date = null;
        this.time = null;
      }
    },
    clear() {
      this.date = null;
      this.time = null;
    },
    ok() {
      this.errors.clear();
      if (this.date == '') {
        this.date = null;
      }
      if (this.time == '') {
        this.time = null;
      }
      let dateTimeMs = 0;
      if (this.date) {
        dateTimeMs = moment.utc(this.date, 'YYYY-MM-DD').valueOf();
      }
      if (this.time) {
        dateTimeMs += moment.utc(`1970-01-01 ${this.time}`, 'YYYY-MM-DD HH:mm:00').valueOf();
      }
      if (dateTimeMs == 0) {
        dateTimeMs = null;
      }

      if (!this.isOptional && dateTimeMs == null) {
        this.errors.add({
          field: 'generic_datetime',
          msg: this.$i18n.t('error.missing_argument', [this.labelTitle])
        });
      }

      this.$nextTick(() => {
        if (this.errors.items.length < 1) {
          this.$emit('ok', { value: dateTimeMs });
          this.$emit('update:show', false);
        } else {
          this.alertError = true;
        }
      });
    },
    hidden() {
      this.$emit('update:show', false);
    },
    resetProperties() {
      this.date = null;
      this.time = null;
      this.alertError = false;
      this.errors.clear();
    }
  }

}
</script>

<style lang="scss" scoped>
.datetime-modal .button-float-left {
  position: absolute;
  left: 15px;
}
</style>